import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Keyboard from 'simple-keyboard';
import { layoutMapping } from './LayoutMapping';
import {
  inscriptLayout,
  sourceLayout,
  remingtonLayout,
} from './MalayalamLayouts';
import {
  firstDisplay,
  altDisplay,
  altshiftDisplay,
  remingtonDisplay,
} from './MalayalamDisplay';
import 'simple-keyboard/build/css/index.css';
import './index.css';
import 'malayalam-fonts/fonts/Manjari/main.css';
import 'malayalam-fonts/fonts/Malini/main.css';
import 'malayalam-fonts/fonts/Nupuram/main.css';
import 'malayalam-fonts/fonts/Nupuram-Color/main.css';
import 'malayalam-fonts/fonts/RIT-Rachana/main.css';
import '@fontsource-variable/anek-malayalam';

const entriesToRemove = [
  '{shiftleft}',
  '{shiftright}',
  '{backspace}',
  '{tab}',
  '{altright}',
  '{altleft}',
  '{lock}',
  '{enter}',
  '{space}',
];

const addOverlayLayout = ({ recurseButtons }) => {
  recurseButtons((button) => {
    const buttonLabel = button.getAttribute('data-skbtn');

    if (!entriesToRemove.includes(buttonLabel)) {
      const span = document.createElement('span');
      span.classList.add('alt');
      span.textContent = buttonLabel.trim();
      button.appendChild(span);
    }
  });
};

let checkbox = document.getElementById('checkbox');
checkbox.addEventListener('change', () => {
  localStorage.setItem('checkbox', checkbox.checked);
  keyboard.options.physicalKeyboardHighlight = checkbox.checked;
  console.log(
    'Physical keyboard enabled:',
    keyboard.options.physicalKeyboardHighlight
  );
});

// Event listener for checkbox change
let layoutcheck = document.getElementById('layoutcheckbox');
layoutcheck.addEventListener('change', createKeyboardInstance);

let darkmodecheck = document.getElementById('darkmodecheckbox');
darkmodecheck.addEventListener('change', darkmodHandle);

let checked = JSON.parse(localStorage.getItem('checkbox'));
document.getElementById('checkbox').checked = checked;

let remingtonchecked = JSON.parse(localStorage.getItem('layoutcheckbox'));
document.getElementById('layoutcheckbox').checked = remingtonchecked;

let darkmodeChecked = JSON.parse(localStorage.getItem('darkmodecheck'));
document.getElementById('darkmodecheckbox').checked = darkmodeChecked;

let currentFont = localStorage.getItem('currentFont');
document.getElementById('fontSelector').selectedIndex = currentFont;

function darkmodHandle() {
  localStorage.setItem('darkmodecheck', darkmodecheck.checked);
  if (darkmodecheck.checked) {
    console.log('is darkmode', darkmodecheck.checked);
    keyboard.setOptions({
      theme: 'simple-keyboard hg-theme-default hg-layout-default myTheme1',
      physicalKeyboardHighlightBgColor: '#1c4995',
    });
    document.body.setAttribute('data-bs-theme', 'dark');
  } else {
    console.log('is darkmode', darkmodecheck.checked);
    keyboard.setOptions({
      theme: 'simple-keyboard hg-theme-default hg-layout-default',
      physicalKeyboardHighlightBgColor: 'green',
    });
    document.body.setAttribute('data-bs-theme', '');
  }
}

let fontSelector = document.getElementById('fontSelector');
fontSelector.addEventListener('change', changeFont);
function changeFont() {
  let selectedFont = fontSelector.options[fontSelector.selectedIndex].value;
  localStorage.setItem('currentFont', fontSelector.selectedIndex);
  let inputElement = document.querySelector('.input');
  inputElement.style.fontFamily = selectedFont;
}

let commonKeyboardOptions = {
  onChange: (input, ev) => onChange(input, ev),
  onKeyPress: (button, ev) => onKeyPress(button, ev),
  onKeyReleased: (button, ev) => onKeyReleased(button, ev),
  onRender: addOverlayLayout,
  onInit: (keyboard) => onInit(keyboard),
  theme: 'simple-keyboard hg-theme-default hg-layout-default',
  physicalKeyboardHighlight: checkbox.checked,
  physicalKeyboardHighlightPress: true,
  mergeDisplay: true,
  tabCharOnTab: true,
  autoUseTouchEvents: false,
  physicalKeyboardHighlightTextColor: 'white',
  physicalKeyboardHighlightBgColor: 'green',
  physicalKeyboardHighlightPressUsePointerEvents: true,
  disableButtonHold: true,
  physicalKeyboardHighlightPressUseClick: true,
  // debug: true,
};

let defaultDisplay = firstDisplay;
let keyboard;

function createKeyboardInstance() {
  if (keyboard) {
    keyboard.destroy();
    // if (checkbox.checked) checkbox.checked = false;
  }

  localStorage.setItem('layoutcheckbox', layoutcheck.checked);
  const isChecked = document.getElementById('layoutcheckbox').checked;
  defaultDisplay = isChecked ? remingtonDisplay : firstDisplay;
  let kbName = isChecked
    ? 'Poorna Extended Remington Keyboard'
    : 'Poorna Extended Inscript Keyboard';
  let kbNameElement = document.querySelector('.kb-name');
  kbNameElement.innerText = kbName;
  if (isChecked) {
    // If checkbox is checked, create remingtonkeyboard instance
    keyboard = new Keyboard({
      ...commonKeyboardOptions,
      layout: sourceLayout,
      targetLayout: remingtonLayout,
      display: remingtonDisplay,
      modules: [layoutMapping],
    });
  } else {
    // If checkbox is not checked, create keyboard instance
    keyboard = new Keyboard({
      ...commonKeyboardOptions,
      layout: sourceLayout,
      targetLayout: inscriptLayout,
      display: firstDisplay,
      modules: [layoutMapping],
    });
  }
  darkmodHandle();
}

// Initial creation based on checkbox status
createKeyboardInstance();

/**
 * Update simple-keyboard when input is changed directly
 */
document.querySelector('.input').addEventListener('input', (event) => {
  keyboard.setInput(event.target.value);
});

document.addEventListener('keydown', function (event) {
  const keyCodeRegex = /^(?:[1-9]|[1-9][0-9]|[12][0-9]{2}|300)$/;
  // event.preventDefault();
  if (keyCodeRegex.test(String(event.keyCode)) && event.altKey === true) {
    event.preventDefault();
  }
  const asciiRegex = /^[\x00-\xFF]*$/;
  if (!asciiRegex.test(event.key)) {
    // Input is valid, clear any previous notices
    document.getElementById('notice').style.display = 'block';
  } else {
    document.getElementById('notice').style.display = 'none';
  }

  document.getElementById('maintext').focus();
});

function onInit(keyboard) {
  let textareaContent = localStorage.getItem('autosave_content');
  keyboard.setInput(textareaContent);
  let inputElement = document.querySelector('.input');
  inputElement.value = textareaContent;
}

let altPressed = false;
let shiftPressed = false;
let timeoutId;
function onChange(input) {
  let inputElement = document.querySelector('.input');
  inputElement.value = input;
  keyboard.setInput(input);

  console.log('Input changed', input);
  /**
   * Synchronizing input caret position
   */
  let caretPosition = keyboard.caretPosition;
  if (caretPosition !== null)
    setInputCaretPosition(inputElement, caretPosition);
  console.log('caretPosition', caretPosition);

  //autosave the content in textarea
  // Clear previous timeout
  clearTimeout(timeoutId);

  // Set a new timeout to save input after 1 second
  timeoutId = setTimeout(function () {
    // Save input to local storage
    localStorage.setItem('autosave_content', input);
  }, 1000); // 1 second delay
}

function setInputCaretPosition(elem, pos) {
  if (elem.setSelectionRange) {
    elem.focus();
    elem.setSelectionRange(pos, pos);
  }
}

function onKeyPress(button, ev) {
  if (event.repeat) {
    return;
  }
  /**
   * If you want to handle the shift and caps lock buttons
   */
  if (event.ctrlKey) {
    return;
  }

  if (button === '{shiftleft}' || button === '{shiftright}') {
    shiftPressed = !shiftPressed;
    console.log('shift pressed is', shiftPressed);
    console.log('altpressed is', altPressed);
  }
  if (button === '{altright}') {
    altPressed = !altPressed;
    console.log('altpressed is', altPressed);
    console.log('shift pressed is', shiftPressed);
  }

  if (
    (button === '{shiftleft}' ||
      button === '{shiftright}' ||
      button === '{altright}') &&
    shiftPressed &&
    !altPressed
  ) {
    handleShift();
    keyboard.setOptions({
      buttonTheme: [
        {
          class: 'hg-red',
          buttons: '{shiftleft} {shiftright}',
        },
      ],
    });
  } else if (
    (button === '{shiftleft}' ||
      button === '{shiftright}' ||
      button === '{altright}') &&
    !shiftPressed &&
    !altPressed
  ) {
    handleShift();
    keyboard.setOptions({
      buttonTheme: [],
    });
  } else if (
    (button === '{shiftleft}' ||
      button === '{shiftright}' ||
      button === '{altright}') &&
    altPressed &&
    !shiftPressed
  ) {
    handleAlt();
    keyboard.setOptions({
      buttonTheme: [
        {
          class: 'hg-red',
          buttons: '{altright}',
        },
      ],
    });
  } else if (
    (button === '{shiftleft}' ||
      button === '{shiftright}' ||
      button === '{altright}') &&
    altPressed &&
    shiftPressed
  ) {
    handleAltShift();
    keyboard.setOptions({
      buttonTheme: [
        {
          class: 'hg-red',
          buttons: '{altright} {shiftright} {shiftleft}',
        },
      ],
    });
  }
}

function onKeyReleased(button, ev) {
  if (keyboard.options.physicalKeyboardHighlight) {
    console.log('event on key released', ev);
    onKeyPress(button);
  }
}

function handleShift() {
  console.log('shiftpressed is', shiftPressed);

  console.log('altpressed is', altPressed);
  let currentLayout = keyboard.options.layoutName;

  // let shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

  if (currentLayout === 'default') {
    keyboard.setOptions({
      layoutName: 'shift',
      display: defaultDisplay,
    });
  } else if (currentLayout === 'shift') {
    keyboard.setOptions({
      layoutName: 'default',
      display: defaultDisplay,
    });
  } else if (currentLayout === 'alt') {
    keyboard.setOptions({
      layoutName: 'default',
      display: defaultDisplay,
    });
  } else if (currentLayout === 'altshift') {
    keyboard.setOptions({
      layoutName: 'shift',
      display: defaultDisplay,
    });
  }

  // keyboard.setOptions({
  //   layoutName: shiftToggle,
  //   display: firstDisplay,
  // });
  console.log('current layout iss', keyboard.options.layoutName);
}

function handleAlt() {
  let currentLayout = keyboard.options.layoutName;

  if (currentLayout === 'default') {
    keyboard.setOptions({
      layoutName: 'alt',
      display: altDisplay,
    });
  } else if (currentLayout === 'alt') {
    keyboard.setOptions({
      layoutName: 'default',
      display: defaultDisplay,
    });
  } else if (currentLayout === 'altshift') {
    keyboard.setOptions({
      layoutName: 'alt',
      display: altDisplay,
    });
  }

  console.log('current layout is', keyboard.options.layoutName);
}

function handleAltShift() {
  let currentLayout = keyboard.options.layoutName;
  // let altToggle = currentLayout === 'default' ? 'alt' : 'default';
  if (currentLayout === 'alt') {
    keyboard.setOptions({
      layoutName: 'altshift',
      display: altshiftDisplay,
    });
  } else if (currentLayout === 'altshift') {
    console.log('changed to first display');
    keyboard.setOptions({
      layoutName: 'default',
      display: defaultDisplay,
    });
  } else if (currentLayout === 'shift') {
    console.log('changed to first display');
    keyboard.setOptions({
      layoutName: 'altshift',
      display: altshiftDisplay,
    });
  }
  console.log('current layout iss', keyboard.options.layoutName);
}

document.addEventListener('DOMContentLoaded', function () {
  var reader = new FileReader();
  var fileInput = document.getElementById('fileupload');

  console.log('File input element:', fileInput);

  // Add an event listener for the 'change' event on the file input
  fileInput.addEventListener('change', function () {
    // Check if there is a file selected in the file input
    if (fileInput.files.length > 0) {
      var fileToRead = fileInput.files[0];
      console.log('File loaded');

      reader.addEventListener('loadend', function () {
        let inputElement = document.querySelector('.input');
        inputElement.value = reader.result;
        keyboard.setInput(reader.result);
      });

      // Start reading the loaded file
      reader.readAsText(fileToRead);
    } else {
      console.error('No file selected.');
    }
  });
  const textareas = document.querySelectorAll(
    'textarea[data-readonly="readonly"]'
  );
  const allowedKeys = {
    13: 'NumpadEnter',
    37: 'arrow-left',
    38: 'arrow-up',
    39: 'arrow-right',
    40: 'arrow-down',
    9: 'tab',
    27: 'esc',
    17: 'Control',
    36: 'Home',
    35: 'End',
    46: 'Delete',
    33: 'PageUp',
    34: 'PageDown',
    96: 'Numpad0',
    97: 'Numpad1',
    98: 'Numpad2',
    99: 'Numpad3',
    100: 'Numpad4',
    101: 'Numpad5',
    102: 'Numpad6',
    103: 'Numpad7',
    104: 'Numpad8',
    105: 'Numpad9',
    106: 'NumpadMultiply',
    107: 'NumpadAdd',
    108: 'NumpadEnter',
    109: 'NumpadSubtract',
    110: 'NumpadDecimal',
    111: 'NumpadDivide',
    144: 'NumLock',
  };

  textareas.forEach(function (textarea) {
    textarea.addEventListener('keydown', function (e) {
      console.log(e.which);
      if (!allowedKeys[e.which] && !e.ctrlKey) {
        e.preventDefault();
      }
    });
  });
});

// methode to clear input area
document.getElementById('cleartext').addEventListener('click', function () {
  // Your function logic goes here
  let inputElement = document.querySelector('.input');
  inputElement.value = null;
  keyboard.setInput(null);
  localStorage.setItem('autosave_content', '');
});

// Methode to copy textarea
document.getElementById('copytext').addEventListener('click', function () {
  // Your function logic goes here
  let inputElement = document.querySelector('.input');
  // inputElement.select();
  navigator.clipboard.writeText(inputElement.value);
});

//Methode to download as txt
document.getElementById('downloadtext').addEventListener('click', function () {
  // Your function logic goes here
  let inputElementVal = document.querySelector('.input').value;
  if (inputElementVal.trim() !== '') {
    let dateval = new Date().toLocaleString();
    var blob = new Blob([inputElementVal], { type: 'text/plain' });
    var url = URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = 'poorna_file_' + dateval + '.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    // Optionally, revoke the Object URL to free up resources
    // URL.revokeObjectURL(url);
  } else {
    alert('Please enter some text before downloading.');
  }
});

//set readonly on mobile devices
function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

// Set readonly attribute based on device type
function setReadonlyOnMobile() {
  var textarea = document.getElementById('maintext');
  if (isMobileDevice()) {
    textarea.setAttribute('readonly', 'readonly');
  }
}

//help popup
document.getElementById('popupopen').addEventListener('click', function () {
  let popup = document.getElementById('popup');
  popup.style.display = 'block';
});
document.getElementById('popupclose').addEventListener('click', function () {
  let popup = document.getElementById('popup');
  popup.style.display = 'none';
});

// Call the functions on page load
setReadonlyOnMobile();
darkmodHandle();
changeFont();
