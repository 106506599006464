let firstDisplay = {
  ']': 'zwj',
  '‌\\': 'zwnj',
  '{tab}': 'tab ⇥',
  '{backspace}': 'backspace ⌫',
  '{enter}': 'enter ↵',
  '{capslock}': 'caps lock ⇪',
  '{shiftleft}': 'shift ⇧',
  '{shiftright}': 'shift ⇧',
  '{controlleft}': 'ctrl ⌃',
  '{controlright}': 'ctrl ⌃',
  '{altleft}': 'alt ⌥',
  '{altright}': 'alt ⌥',
  '{metaleft}': 'cmd ⌘',
  '{metaright}': 'cmd ⌘',
  '`': 'ൊ',
  '~': 'ഒ',
  '*': 'ൾ',
  _: 'ഃ',
  '+': 'ഋ',
  '=': 'ൃ',
  q: 'ൗ',
  w: 'ൈ',
  e: 'ാ',
  r: 'ീ',
  t: 'ൂ',
  y: 'ബ',
  u: 'ഹ',
  i: 'ഗ',
  o: 'ദ',
  p: 'ജ',
  '[': 'ഡ',
  ']': 'zwj',
  '\\': 'zwnj',
  a: 'ോ',
  s: 'േ',
  d: '്',
  f: 'ി',
  g: 'ു',
  h: 'പ',
  j: 'ര',
  k: 'ക',
  l: 'ത',
  ';': 'ച',
  "'": 'ട',
  z: 'െ',
  x: 'ം',
  c: 'മ',
  v: 'ന',
  b: 'വ',
  n: 'ല',
  m: 'സ',
  ',': ',',
  '.': '.',
  '/': 'യ',
  Q: 'ഔ',
  W: 'ഐ',
  E: 'ആ',
  R: 'ഈ',
  T: 'ഊ',
  Y: 'ഭ',
  U: 'ങ',
  I: 'ഘ',
  O: 'ധ',
  P: 'ഝ',
  '{': 'ഢ',
  '}': 'ഞ',
  '|': 'ർ',
  A: 'ഓ',
  S: 'ഏ',
  D: 'അ',
  F: 'ഇ',
  G: 'ഉ',
  H: 'ഫ',
  J: 'റ',
  K: 'ഖ',
  L: 'ഥ',
  ':': 'ഛ',
  '"': 'ഠ',
  Z: 'എ',
  X: 'ൺ',
  C: 'ണ',
  V: 'ൻ',
  B: 'ഴ',
  N: 'ള',
  M: 'ശ',
  '<': 'ഷ',
  '>': 'ൽ',
  '?': '?',
};

let altDisplay = {
  '{tab}': 'tab ⇥',
  '{backspace}': 'backspace ⌫',
  '{enter}': 'enter ↵',
  '{capslock}': 'caps lock ⇪',
  '{shiftleft}': 'shift ⇧',
  '{shiftright}': 'shift ⇧',
  '{controlleft}': 'ctrl ⌃',
  '{controlright}': 'ctrl ⌃',
  '{altleft}': 'alt ⌥',
  '{altright}': 'alt ⌥',
  '{metaleft}': 'cmd ⌘',
  '{metaright}': 'cmd ⌘',
  1: '൰',
  2: '൱',
  3: '൲',
  4: '₹',
  5: '൳',
  6: '൴',
  7: '൵',
  8: '*',
  9: '{',
  0: '}',
  '-': '_',
  '=': 'ൄ',
  q: 'ൌ',
  w: 'x',
  e: 'ൟ',
  r: 'ൣ',
  t: 'x',
  y: 'x',
  u: 'x',
  i: 'x',
  o: 'x',
  p: 'x',
  '[': '[',
  ']': ']',
  '\\': '\\',
  a: 'x',
  s: '഻',
  d: 'ഽ',
  f: 'ൢ',
  g: 'x',
  h: '൏',
  j: 'ൎ',
  k: 'x',
  l: 'x',
  ';': ';',
  "'": "'",
  z: 'x',
  x: 'ഀ',
  c: 'x',
  v: '൹',
  b: 'x',
  n: 'x',
  m: 'x',
  ',': '<',
  '.': '>',
  '/': '/',
};

let altshiftDisplay = {
  '{tab}': 'tab ⇥',
  '{backspace}': 'backspace ⌫',
  '{enter}': 'enter ↵',
  '{capslock}': 'caps lock ⇪',
  '{shiftleft}': 'shift ⇧',
  '{shiftright}': 'shift ⇧',
  '{controlleft}': 'ctrl ⌃',
  '{controlright}': 'ctrl ⌃',
  '{altleft}': 'alt ⌥',
  '{altright}': 'alt ⌥',
  '{metaleft}': 'cmd ⌘',
  '{metaright}': 'cmd ⌘',
  '~': '~',
  '!': '൧',
  '@': '൨',
  '#': '൩',
  $: '൪',
  '%': '൫',
  '^': '൬',
  '&': '൭',
  '*': '൮',
  '(': '൯',
  ')': '൦',
  _: '',
  '+': 'ൠ',
  Q: '൞',
  W: '൷',
  E: '൜',
  R: 'ൡ',
  T: '൶',
  Y: '൛',
  U: '൙',
  I: '൘',
  O: '൸',
  P: '൝',
  '{': '൚',
  '}': '+',
  '|': '|',
  A: 'x',
  S: '഼',
  D: 'ഁ',
  F: 'ഌ',
  G: 'x',
  H: 'x',
  J: 'ഺ',
  K: 'ൿ',
  L: 'x',
  ':': ':',
  '"': '"',
  Z: 'x',
  X: 'ഄ',
  C: 'ൔ',
  V: 'ഩ',
  B: 'ൖ',
  N: 'x',
  M: 'x',
  '<': 'x',
  '>': 'x',
  '?': 'ൕ',
};

let remingtonDisplay = {
  '{tab}': 'tab ⇥',
  '{backspace}': 'backspace ⌫',
  '{enter}': 'enter ↵',
  '{capslock}': 'caps lock ⇪',
  '{shiftleft}': 'shift ⇧',
  '{shiftright}': 'shift ⇧',
  '{controlleft}': 'ctrl ⌃',
  '{controlright}': 'ctrl ⌃',
  '{altleft}': 'alt ⌥',
  '{altright}': 'alt ⌥',
  '{metaleft}': 'cmd ⌘',
  '{metaright}': 'cmd ⌘',
  '`': 'zwnj',
  '~': 'zwj',
  '!': 'ഝ',
  '@': 'ആ',
  '#': 'ഈ',
  $: 'ഊ',
  '%': 'ഏ',
  '^': 'ഐ',
  '&': 'ഓ',
  '*': 'ഔ',
  '(': 'ൊ',
  ')': 'ോ',
  _: 'ഠ',
  '+': 'ൌ',
  Q: 'ഒ',
  W: 'ഋ',
  E: 'ഫ',
  R: 'ൻ',
  T: 'ഇ',
  Y: '്ര',
  U: '്യ',
  I: '്വ',
  O: 'ൗ',
  P: 'ധ',
  '{': 'ൃ',
  '}': 'ട്ട',
  A: 'ം',
  S: 'േ',
  D: 'ഖ',
  F: 'ഥ',
  G: 'ർ',
  H: 'ൽ',
  J: 'ീ',
  K: 'ഉ',
  L: 'അ',
  ':': ';',
  '"': 'ഃ',
  Z: 'ഘ',
  X: 'ൾ',
  C: 'ശ',
  V: 'ഛ',
  B: 'ഞ',
  N: 'ൺ',
  M: 'റ്റ',
  '<': 'ഹ',
  '>': 'ബ',
  '?': 'ഢ',
  '=': 'ൈ',
  q: 'ഴ',
  w: 'ജ',
  e: 'പ',
  r: 'ന',
  t: 'എ',
  y: 'റ',
  u: 'യ',
  i: 'വ',
  o: 'ൂ',
  p: 'ദ',
  '[': ',',
  ']': 'ക്ക',
  '\\': '്',
  a: 'മ',
  s: 'െ',
  d: 'ക',
  f: 'ത',
  g: 'ര',
  h: 'ല',
  j: 'ി',
  k: 'ു',
  l: 'ാ',
  ';': '.',
  "'": 'ന്ന',
  z: 'ഗ',
  x: 'ള',
  c: 'സ',
  v: 'ച',
  b: 'ങ',
  n: 'ണ',
  m: 'ട',
  ',': 'ഷ',
  '.': 'ഭ',
  '/': 'ഡ',
};
export { firstDisplay, altDisplay, altshiftDisplay, remingtonDisplay };
