let altlayout = [
  '` ൰ ൱ ൲ ₹ ൳ ൴ ൵ * { } _ ൄ {bksp}',
  '{tab} ൌ  ൟ ൣ       [ ‍] \\',
  "{capslock}  ഻ ഽ ൢ  ൏ ൎ   ; ' {enter}",
  '{shiftleft}  ഀ  ൹    < > / {shiftright}',
  '{altleft} {space} {altright}',
];
let altshiftlayout = [
  '~ ൧ ൨ ൩ ൪ ൫ ൬ ൭ ൮ ൯ ൦ _ ൠ {bksp}',
  '{tab} ൞ ൷ ൜ ൡ ൶ ൛ ൙ ൘ ൸ ൝ ൚ + |',
  '{capslock}  ഼ ഁ ഌ   ഺ ൿ  : " {enter}',
  '{shiftleft}  ഄ ൔ ഩ ൖ     ൕ {shiftright}',
  '{altleft} {space} {altright}',
];

const sourceLayout = {
  default: [
    '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
    '{tab} q w e r t y u i o p [ ] \\',
    "{lock} a s d f g h j k l ; ' {enter}",
    '{shiftleft} z x c v b n m , . / {shiftright}',
    '{altleft} {space} {altright}',
  ],
  shift: [
    '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
    '{tab} Q W E R T Y U I O P { } |',
    '{lock} A S D F G H J K L : " {enter}',
    '{shiftleft} Z X C V B N M < > ? {shiftright}',
    '{altleft} {space} {altright}',
  ],
  alt: [
    '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
    '{tab} q w e r t y u i o p [ ] \\',
    "{lock} a s d f g h j k l ; ' {enter}",
    '{shiftleft} z x c v b n m , . / {shiftright}',
    '{altleft} {space} {altright}',
  ],
  altshift: [
    '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
    '{tab} Q W E R T Y U I O P { } |',
    '{lock} A S D F G H J K L : " {enter}',
    '{shiftleft} Z X C V B N M < > ? {shiftright}',
    '{altleft} {space} {altright}',
  ],
};

const inscriptLayout = {
  default: [
    'ൊ 1 2 3 4 5 6 7 8 9 0 - ൃ {bksp}',
    '{tab} ൗ ൈ ാ ീ ൂ ബ ഹ ഗ ദ ജ ഡ ‍ ‌',
    '{capslock} ോ േ ് ി ു പ ര ക ത ച ട {enter}',
    '{shiftleft} െ ം മ ന വ ല സ , . യ {shiftright}',
    '{altleft} {space} {altright}',
  ],
  shift: [
    'ഒ ! @ # $ % ^ & ൾ ( ) ഃ ഋ {bksp}',
    '{tab} ഔ ഐ ആ ഈ ഊ ഭ ങ ഘ ധ ഝ ഢ ഞ ർ',
    '{capslock} ഓ ഏ അ ഇ ഉ ഫ റ ഖ ഥ ഛ ഠ {enter}',
    '{shiftleft} എ ൺ ണ ൻ ഴ ള ശ ഷ ൽ ? {shiftright}',
    '{altleft} {space} {altright}',
  ],
  alt: altlayout,
  altshift: altshiftlayout,
};

const remingtonLayout = {
  default: [
    '‌ 1 2 3 4 5 6 7 8 9 0 - ൈ {bksp}',
    '{tab} ഴ ജ പ ന എ റ യ വ ൂ ദ , ക്ക ്',
    '{capslock} മ െ ക ത ര ല ി ു ാ . ന്ന {enter}',
    '{shiftleft} ഗ ള സ ച ങ ണ ട ഷ ഭ ഡ {shiftright}',
    '{altleft} {space} {altright}',
  ],
  shift: [
    '‍ ഝ ആ ഈ ഊ ഏ ഐ ഓ ഔ ൊ ോ ഠ ൌ {bksp}',
    '{tab} ഒ ഋ ഫ ൻ ഇ ്ര ്യ ്വ ൗ ധ ൃ ട്ട ',
    '{capslock} ം േ ഖ ഥ ർ ൽ ീ ഉ അ ; ഃ {enter}',
    '{shiftleft} ഘ ൾ ശ ഛ ഞ ൺ റ്റ ഹ ബ ഢ {shiftright}',
    '{altleft} {space} {altright}',
  ],
  alt: altlayout,
  altshift: altshiftlayout,
};
export { inscriptLayout, sourceLayout, remingtonLayout };
