export const layoutMapping = (keyboard) => {
  /**
   * Registering module
   */
  keyboard.registerModule('layoutMapping', (module) => {
    let { targetLayout } = keyboard.options;

    module.fn = {};
    module.fn.handleButtonClicked = keyboard.handleButtonClicked;

    keyboard.handleButtonClicked = (button, ev) => {
      if (ev.ctrlKey || ev.which == 13) {
        console.log('evcode handlebutn', ev.which);
        return;
      }
      console.log('** SOURCE BUTTON ***', button, ev);
      const targetButton = module.getButtonInTargetLayout(button);
      console.log('** TARGET BUTTON ***', targetButton, ev);
      module.fn.handleButtonClicked(targetButton, ev);
    };

    module.getButtonInTargetLayout = (button) => {
      const buttonInLayout = module.getButtonInLayout(button);
      const sourceButtonElement = Array.isArray(buttonInLayout)
        ? buttonInLayout[0]
        : buttonInLayout;
      if (!sourceButtonElement) return;

      console.log(sourceButtonElement);

      const skbtnuid = sourceButtonElement.getAttribute('data-skbtnuid');
      const [sourceButtonLayoutName, sourceBtnLocation] = skbtnuid.split('-');
      const [sourceBtnRow, sourceBtnIndex] = sourceBtnLocation
        .replace('r', '')
        .split('b');

      const targetButton = module.findLayoutKeyByIndex(
        sourceBtnRow,
        sourceBtnIndex,
        targetLayout,
        sourceButtonLayoutName
      );

      return targetButton;
    };

    /**
     * Get button in layout
     */
    module.getButtonInLayout = (layoutKeyName) => {
      let buttonElement =
        keyboard.getButtonElement(layoutKeyName) ||
        keyboard.getButtonElement(`{${layoutKeyName}}`);

      return buttonElement;
    };

    /**
     * Find layout key by index
     */
    module.findLayoutKeyByIndex = (
      rIndex,
      bIndex,
      layout,
      layoutName = keyboard.options.layoutName
    ) => {
      let row = layout[layoutName][rIndex];

      if (row) {
        let rowButtons = row.split(' ');
        return rowButtons[bIndex];
      }
    };
  });
};
